<template lang="pug">
	#radios
		h3.option Escolha um Estado
		ul.ufs
			li(v-for="tab in tabs" @click="changeAtivo(tab.id)" :class="{ativo: active == tab.id}").cardUf
				span.title {{ tab.title }}
		.container
			div(v-if="active !== null" :class="{'selected': search !== ''}").search
				input(v-model="search" placeholder="Cidade")
				FontAwesomeIcon(:icon="icons.faMagnifyingGlass" v-if="search == ''").searchIcon
			ul(v-if="active !== null").cities
				li(v-for="city, index in filteredList" @click="toggleCity(index)").cardCity
					.head
						span.city {{ city.city }}
						FontAwesomeIcon(:icon="icons.faChevronDown")
					.body(:class="{ open: city.isOpen }")
						ul.cityList
							li(v-for="radio in city.radios").cityItem
								span.radio {{ formatDate(radio.schedule) }}
								span.time {{ radio.name }}
								span.frequency {{ radio.station  }}
</template>

<script>
import { faMagnifyingGlass, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { props } from '@/mixins/component'

export default {
	name: "component-radios",
	props,
	data() {
		return {
			search: '',
			tabsIdentifier: 'radios',
			active: null,
			lista: [],
			icons: {
			    faMagnifyingGlass,
				faChevronDown
			},
		}
	},
	methods: {
		changeAtivo(id) {
			this.active = this.active == id ? null : this.active = id
        },
		toggleCity(index) {
			this.lista[index].isOpen = !this.lista[index].isOpen
		},
		formatDate(date) {
			let hours = date.slice(0,2)
			let minutes = date.slice(3,5)

			let start = `${hours}:${minutes}`
			if (minutes >= 45) {
				hours++
				minutes = `${minutes - 45}`.length === 1 ? `0${minutes - 45}` : minutes - 45 
			} else {
				minutes = +minutes + 15
			}
			const end = `${hours}:${minutes}`
			
			return `${start} às ${end}`
        },
	},
	computed: {
		tabs() {
			return this.$store.getters.tabRadios
		},
		tabActive() {
			return this.tabs.find(tab => tab.id == this.active) || {}
		},
		filteredList() {
			const tab = this.tabs.filter(tab => tab.id === this.active)[0]
			tab.content = tab.content.sort((a, b) => {
				if (a.city < b.city) return -1;
				if (a.city > b.city) return 1;
				return 0
			})
			const newLista = tab.content.filter(option => {
				return option.city.toLowerCase().includes(this.search.toLowerCase())
			})
			this.lista = []
			newLista.forEach(city => {
				const cidade = this.lista.find(cidade => cidade.city == city.city)
				const radio = {
					name: city.name,
					schedule: city.schedule,
					station: city.station
				}
				if (cidade) {
					cidade.radios.push(radio)
				}
				else {
					this.lista.push({
						radios: [radio],
						city: city.city,
						isOpen: false
					})
				}
			})
			return this.lista
		}
	},
	created() {
		this.$store.dispatch('fetchRadios')
	}
}
</script>

<style lang="stylus" scoped src="./ListaRadios.styl"></style>